.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
li{
  list-style: none;
}
.App-link {
  color: #61dafb;
}
.validation-font{
  font-size: 11px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px #194c67;  */
  border-radius: 8px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #64748B ; 
  border-radius: 10px;
}
.validation-length{
  width: 1247px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F26539; 
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 7%;
}

.content {
  flex-grow: 1;
  padding-bottom: 3%;
}

.contentAssessment {
  flex-grow: 1;
  padding-bottom: 0%;
}

.pagination {
  position: absolute; 
   
  bottom: 50px;
  left: 0;
  width: 100%;
}

.footer {

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f5f5f5;
  padding: 3px;
  text-align: center ;
}

.header-cardNew {
  /* padding: 0px 0; */
  background-color: #f6f8fc !important;
  border-radius: 20px !important;
}

.header-cardMain {
  padding: 8px 0;
  background-color: #f6f8fc !important;
  border-radius: 20px !important;
}

.custom-accordion {
  border-radius: 20px !important;
  
  /* Additional custom styles for the accordion */
}

.searchbox {
  padding: 0px 0px !important;
}

.my-list {
  list-style-type: disc;
  margin-left: 1em;
}

.my-list-w {
  /* list-style-type: disc !important; */
  display: block;
  text-align: start;
  overflow: hidden;
}