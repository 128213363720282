.chat_container {
  height: calc(100% - 4rem);
  overflow: hidden;
  margin: 1rem auto;
  padding: 1rem;
  /* max-width: 600px; */
  width: 70%;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.chat_view_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}
.chat_query {
  border-radius: 0.4rem;
  margin: 0.5rem 0.5rem 0.5rem auto;
  max-width: 80%;
  background-color: #c5c8ed;
  padding: 0.4rem 0.8rem;
  display: block;
  width: fit-content;
  font-weight: 600;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.chat_response {
  border-radius: 0.4rem;
  border-top-right-radius: 20px;
  margin: 0.5rem;
  max-width: 80%;
  background-color: #eeeeee;
  padding: 0.4rem 0.8rem;
  padding-bottom: 2.1rem;
  display: block;
  position: relative;
  width: fit-content;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.yes_no_container {
  position: absolute;
  bottom: 5px;
  right: 2px;
}

.copy_tab {
  padding: 0.2rem 0.5rem;
  background-color: #636bce;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 600;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.input_submit_container {
  display: flex;
}
.input_submit_container > input {
  flex: 1 1;
  padding: 0.75rem 1rem;
  border-bottom-left-radius: 0.5rem;
  border: 2px solid lightgrey;
  outline: none;
  font-size: 1.1em;
}
.input_submit_container > button {
  border-bottom-right-radius: 0.5rem;
  border: 2px solid #636bce;
  background-color: #636bce;
  color: white;
  font-weight: 600;
  font-size: 1.1em;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
}
