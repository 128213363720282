/** @format */

.sidedrawer > div {
  /* position: inherit !important; */
  margin: 0 auto;
  align-items: baseline;
}

.hover-saidenav {
  width: 100% !important;
}

.sidedrawer {
  z-index: 9999 !important;
}

.fontsize-link {
  font-size: 10px !important;
}

.navigation-link:hover {
  text-decoration: underline !important;
  color: rgb(0, 114, 229) !important;
}
.navigation-link {
  color: rgb(0, 114, 229);
  text-decoration: none;
}
.title-head {
  margin: 0 !important;
  text-align: left;
  align-items: center;
  margin-bottom: 5px !important;
  width: auto !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.company-head {
  margin: 0 !important;
  text-align: left;
  align-items: center;
  margin-bottom: 5px !important;
}

.mobile-sidedrawer > div {
  width: calc(80px + 2px) !important;
}

.bg-sidebar-main > div {
  background-color: #f6f8fc;
}

.border-box {
  align-items: center;
  display: flex;
  background: #f6f8fc;
  padding: 0px 10px;
  border-radius: 8px;
  height: fit-content;
  width: fit-content !important;
}

.link-side {
  text-decoration: none;
  display: contents;
}

.logo-image {
  margin: 10px 0px 0px 10px;
  text-align: center;
  width: 50px;
  align-items: center;
  font-size: 10px;
  display: flex;
}

.image-logo-top {
  width: 62px;
  height: 42px;
  margin-right: 20px;
}

.main-header {
  background-color: #e2e8f0 !important;
  color: #27364b !important;
  box-shadow: none !important;
  padding: 10px;
}

.main-header > div {
  display: flex;
  justify-content: space-between;
}

.main-body {
  background: #e2e8f0;
  /* margin: 80px 10px 10px 10px; */
  border-radius: 10px;
}

.collapse-menu {
  margin-bottom: 10px !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom: none !important;
}

.nav-icon {
  /* display: flex !important; */
  flex-direction: row !important;
  align-items: center !important;
  background: #ffffff !important;
  border: 1px solid #cbd4e1 !important;
  border-radius: 8px !important;
  /* padding: 5px !important; */
  color: #27364b;
}

.nav-icon:hover {
  background-color: #ffe6d6 !important;
  color: #f26539 !important;
  border-radius: 8px !important;
  border: 1px solid #f26539 !important;
}

.nav-icon > div:hover {
  background-color: transparent !important;
}

.menu-link {
  margin-bottom: 5px !important;
}

.side-submenu {
  border: 1px solid #cbd4e1 !important;
  border-radius: 10px !important;
  margin-left: 25px !important;
}

/* .side-submenu > a > ul {
  /* border-bottom: 1px solid #cbd4e1 !important; */
/* border-radius: 5px !important; 
} */

.side-submenu > a > ul > div {
  padding-left: 16px !important;
}

.side-submenu > a > ul:hover {
  background-color: #f9e1cb !important;
  color: #f26539 !important;
  /* border-radius: 8px !important; */
}

/* .nav-button:hover {
  background-color: #f9e1cb !important;
  border-color: #f26539 !important;
  color: #f26539 !important;
  border-radius: 8px !important;
  border: 1px solid #f26539 !important;
} */

.nav-button {
  width: 100% !important;
}

/* .nav-button:focus {
  background-color: #f26539 !important;
  border-color: #f26539 !important;
  border-radius: 8px !important;
  color: #fff !important;
} */
.Sub-Role-Select {
  min-width: -webkit-fill-available !important;
  max-width: 400px;
  height: 36px;
}

.submission-stack {
  flex-direction: row !important;
}

.nav-icon > div {
  padding: 0 !important;
  margin: 0 !important;
  /* -webkit-justify-content: center !important; */
  align-items: center !important;
  border-radius: 8px !important;
  justify-content: unset !important;
}

.active {
  background-color: #f26539 !important;
  border-color: #f26539 !important;
  border-radius: 8px !important;
  color: #fff !important;
}

.submenu-item {
  text-decoration: none;
  color: #27364b !important;
}

.subrolestack {
  display: flex !important;
  float: right;
  gap: 10px !important;
}

.company-list-link {
  text-decoration: none !important;
  color: #27364b !important;
  font-weight: bold !important;
}

.submenu-item > a {
  text-decoration: none;
  color: #27364b !important;
}

.submenu-item > div:hover {
  background-color: #ffe6d6 !important;
  color: #f26539 !important;
}

.submenu-item > div {
  border: 1px solid #cbd4e1;
  margin-left: 30px;
  font-weight: bold;
  color: #27364b;
}

/* .css-17jjc08-MuiDataGrid-footerContainer {
  display: none !important;
} */
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f5f5f5;
  padding: 7px 0;
  text-align: center;
  z-index: 1000;
}

.title-text {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.title-text > svg {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.title-text > span {
  /* font-family: "Inter"; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-left: 10px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.dasboad-main {
  display: flex !important;
}

.dasboad-main-content {
  margin-top: 68px;
  position: absolute;
  width: 100%;
  padding: 35px 10px 4px 90px !important;
  background-color: #e2e8f0 !important;
  overflow: auto;
  height: -webkit-fill-available;
}

/* .company2 {
  display: block !important;
  min-width: 0 !important
} */

.company2:hover {
  /* background-color: #eac5ba !important;
  border-color: #F26539 !important;
  border: 1px solid #F26539 !important;
  color: #F26539 !important;
  border-radius: 5px !important; */
  /* min-width: 0 !important; */
  /* width: 100% !important;
  height: 100% !important;
  padding: 0px !important; */
  /* display: block !important; */
  background-color: #ffe6d6 !important;
  border-color: #f26539 !important;
  /* display: block !important; */
  /* min-width: 0 !important; */
  /* width: 70px !important; */
  color: #f26539 !important;
  border: 1px solid #f26539 !important;
  border-radius: 5px !important;
  /* padding: 0px !important; */
  margin: 5px !important;
}

.company1 {
  min-width: 0 !important;
  width: 30px !important;
  margin: 5px !important;
}

.company2 {
  display: flex !important;
  min-width: 0 !important;
  width: 45px !important;
  height: 35px !important;
  color: #27364b !important;
  border: 1px solid #27364b !important;
  border-radius: 5px !important;
  padding: 0px !important;
  background: #fff !important;
  margin: 5px !important;
}

.company-image-logo {
  width: 100px !important;
  height: 100px !important;
  border: 1px solid #cbd4e1 !important;
  border-radius: 10px !important;
}

.company-image-div {
  width: 80% !important;
  height: 80% !important;
}

/* .company2>img:hover{
  height: 10px !important;
} */
/* .button-click{
  background-color: #F26539 !important;
} */
.pathname {
  margin-left: 36px !important;
  text-transform: capitalize !important;
}

.icon-image {
  display: flex !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
}

.image-icon {
  display: flex !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 5px !important;
  min-height: 40px !important;
}

.card-main {
  height: 100vh;
}

/* .scrollhost {
  position: 'relative';
  width: 400;
  overflow-x: 'hidden';
    overflow-y: 'hidden';
}
.scrollhost:hover{
    overflow-y: 'auto';
}
.scrollhost::-webkit-scrollbar{
  display: 'none';
} */

.scrollhost > div::-webkit-scrollbar {
  display: none;
}

.header-item {
  text-decoration: none !important;
  color: #f26539 !important;
  font-size: 7px !important;
  margin: 0;
  padding: 0;
}

.notify-text {
  padding: 3px 5px 3px 5px;
  margin: 0;
  position: absolute;
  top: 0;
  font-size: 12px;
  z-index: 999;
  color: #fff;
  background-color: chocolate;
  border-radius: 75%;
}

.notify-text-company {
  padding: 3px 5px 3px 5px;
  margin: 0;
  position: absolute;
  top: 0;
  font-size: 12px;
  z-index: 999;
  color: #fff;
  background-color: chocolate;
  border-radius: 75%;
}

.table-container {
  overflow-x: auto !important;
  box-shadow: none !important;
  margin-right: 10%;
  border-radius: 8px !important;
}

.tabs-content-parent {
  align-items: center;
}

.tabs-content-parent > div > span {
  background-color: transparent !important;
  transition: none !important;
}

/* .tabs-content.Mui-selected {
  background-color: #64748B !important;
  color: #fff !important;
} */
.tabs-content:focus {
  background-color: #64748b !important;
  color: #fff !important;
}
.tabs-content > P {
  background-color: #f6f8fc !important;
  background-color: #64748b !important;
  border-radius: 100px !important;
  padding: 3px 12px 3px 12px !important;
  color: chocolate !important;
}

.search-bar {
  /* border: 2px solid #cbd4e1 !important;
  border-radius: 8px !important; */
  color: #27364b !important;
  height: 45px;
  text-transform: capitalize !important;
  margin-top: 0px;
}

.header-card-addform {
  margin-bottom: 5px;
  padding: 10px;
}
.allforms-pagination {
  background-color: #f6f8fc;
  border-radius: 8px;
  margin-top: 8px;
}

.cancle-hover {
  border: 1px solid transparent !important;
}

.cancle-hover:hover {
  background-color: #ffe6d6 !important;
  border: 1px solid #f26539 !important;
  color: #f26539 !important;
  text-decoration: none !important;
}

.plus-icon-grid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview-card {
  padding: 10px !important;
  margin-bottom: 16px !important;
}

.overview-select {
  background: url("../assets/icons/svg-icons/Down.svg");
  appearance: none;
  background-repeat: no-repeat;
  background-position: right;
  width: fit-content;
  margin: 0px !important;
}

.header-grid {
  display: flex;
}

.dash-select-overview {
  border: 1px solid #cbd4e1;
  height: 40px;
  border-radius: 8px;
  color: #27364b;
  font-weight: 600;
  display: flex;
  padding: 8px;
  max-width: 150px;
  width: 100%;
}

.date-to {
  display: flex;
  align-items: center;
  justify-content: center;
}

.apply {
  margin-right: 10px !important;
}

.complete-graph {
  background-color: #fff !important;
  height: 100% !important;
  align-items: center;
  justify-content: center !important;
  display: flex !important;
  overflow: auto !important;
  min-height: 410px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  border: 1px solid #cbd4e1 !important;
  border-radius: 8px !important;
}

.due-graph {
  background-color: #fcf0db !important;
  height: 100% !important;
  align-items: center;
  justify-content: center !important;
  display: flex !important;
}

.expire-graph {
  background-color: #fde1e1 !important;
  height: 100% !important;
  align-items: center;
  justify-content: center !important;
  display: flex !important;
}

.content-card {
  margin-top: 10px !important;
}

.overview-list {
  padding: 20px;
  height: 100% !important;
  border-radius: 8px !important;
}

.overview-list > ul > li {
  padding: 10px 5px;
  list-style-type: none;
  display: flex !important;
  font-weight: bold !important;
  align-items: center;
  text-align: center;
}

.overview-list > ul > li > p {
  font-weight: bold !important;
}

.view-all {
  color: #64748b !important;
  font-weight: 100 !important;
  margin-bottom: 15px !important;
  font-size: 18px !important;
}

.details-table > tbody > tr > td {
  padding: 5px 0px 5px 10px !important;
  border-bottom: none !important;
}

.details-table {
  justify-content: center !important;
  max-width: 100% !important;
  display: flex !important;
}

.date-content-grid {
  max-width: 100% !important;
}

.list-value {
  color: #64748b !important;
  text-align: end !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.list-key-value {
  color: #64748b !important;
  text-align: start !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.done-btn {
  background-color: #196773 !important;
  color: #ffffff !important;
  padding: 5px 12px !important;
  border: 1px solid transparent !important;
  text-transform: capitalize !important;
  height: 40px !important;
  border-radius: 8px !important;
}

.overview-map > div {
  background-color: #9fc4f9 !important;
}

.text-short3 {
  width: 200px !important;
  display: block !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden;
  color: #27364b;
}

/* .overview-list>div{
  height: 100% !important;
} */
/* .map {
  height:100%;
}
#overview {
  position:absolute;
  height:100%;
  background-color: #9fc4f9 !important;
} */

.table-left-border-danger {
  border-left: 6px solid #bb251a;
  height: 100%;
  padding: 8px;
  border-color: #bb251a;
  font-weight: 600;
  width: -webkit-fill-available;
  display: flex;
  /* align-items: center;
  justify-content: center; */
}
.tabs-content-parent > div > div {
  gap: 10px;
}

.inspection-tab > div > div {
  padding: 10px;
}

.table-left-border-red {
  border-color: rgb(255, 8, 8);
  padding: 8px;
  font-weight: 600;
  color: #27364b !important;
  border-left: 6px solid rgb(255, 8, 8);
  /* height: 100%; */
  width: -webkit-fill-available;
  display: flex;
  /* align-items: center;
  justify-content: center; */
}

.table-left-border-green {
  border-color: #90EE90;
  padding: 8px;
  font-weight: 600;
  color: #27364b !important;
  border-left: 6px solid #90EE90;
  /* height: 100%; */
  width: -webkit-fill-available;
  display: flex;
  /* align-items: center;
  justify-content: center; */
}
.table-left-border-success {
  border-color: #039855;
  padding: 8px;
  font-weight: 600;
  color: #27364b !important;
  border-left: 6px solid #039855;
  /* height: 100%; */
  width: -webkit-fill-available;
  display: flex;
  /* align-items: center;
  justify-content: center; */
}
.table-left-border-grey {
  border-color: #dedddc;
  padding: 8px;
  font-weight: 600;
  color: #27364b !important;
  border-left: 6px solid #dedddc;
  /* height: 100%; */
  width: -webkit-fill-available;
  display: flex;
  /* align-items: center;
  justify-content: center; */
}
.table-left-border-darkgrey {
  border-color: #484848;
  padding: 8px;
  font-weight: 600;
  color: #27364b !important;
  border-left: 6px solid #484848;
  /* height: 100%; */
  width: -webkit-fill-available;
  display: flex;
  /* align-items: center;
  justify-content: center; */
}

.table-left-border-pending {
  /* background-color: #fff; */
  border-left: 5px solid;
  /* height: 90%; */
  /* padding: 8px; */
  /* border-top-left-radius: 5px; */
  /* border-bottom-left-radius: 5px; */
  border-color: #f39a4c;
  /* font-size: 16px; */
  border-left: 6px solid #f39a4c;
  font-weight: 600;
  height: 100%;
  padding: 8px;
  width: -webkit-fill-available;
}

.tablepagination {
  border-bottom: none !important;
  /* display: contents !important; */
  display: flex !important;
  justify-content: center !important;
}

.tablepagination-userList {
  border-bottom: none !important;
  display: contents !important;
}

.MuiTablePagination-actions {
  float: right;
}

.nodata {
  margin: 15px 0px;
  font-weight: 600;
}

.forms-scroll {
  max-height: 100px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.profile-line > div {
  display: flex !important;
  align-items: center !important;
  font-weight: bold !important;
  /* color: #64748B; */
}

.avtar {
  width: 25px !important;
  height: 25px !important;
  margin: 0px 10px 0px 0px !important;
}

.table-body > tr > td {
  color: #27364b !important;
}

.table-container > table > thead > tr > th {
  color: #27364b !important;
  font-weight: 600;
}

.user {
  display: flex !important;
}

.user-location {
  padding: 5px 10px 5px 0px !important;
  border-bottom: none !important;
  text-align: left !important;
  color: #27364b !important;
  text-align: end !important;
  font-size: 14.5px !important;
}

.user-information {
  padding: 20px 0px 0px 0px;
  /* overflow-x: auto !important; */
  border-radius: 8px !important;
  color: #27364b !important;
  /* padding-right: 10px !important; */
  padding-left: 0px !important;
}

.fit-content {
  height: fit-content;
}

.map {
  display: inline-flex;
  width: fit-content;
  padding: 5px;
  border: 2px solid #cbd4e1;
  background-color: #f6f8fc;
  border-radius: 8px;
}

.Correction {
  display: block !important;
  text-align: left !important;
  background-color: #196773 !important;
  color: #fff !important;
  font-size: 12px !important;
  /* width: 170px !important; */
  border-radius: 8px !important;
  /* margin-right: 5px !important; */
  /* margin-bottom: 8px !important; */
  text-transform: inherit !important;
}

.Correction-second {
  display: block !important;
  text-align: left !important;
  color: #27364b !important;
  font-size: 12px !important;
  border: 1px solid #cbd4e1 !important;
  border-radius: 8px !important;
  /* width: 170px !important; */
  background-color: #f6f8fc !important;
  /* margin-right: 5px !important; */
  text-transform: inherit !important;
  /* margin-bottom: 8px !important; */
}

.button-tabs {
  overflow-x: hidden;
  overflow-y: scroll;
}

.button-tabs {
  scrollbar-color: red yellow;
}

.Questions-tabs.Mui-selected {
  background-color: #196773 !important;
  color: #fff !important;
  border-radius: 10px !important;
  /* width: 200px !important; */
}

.Vertical-tabs.Mui-selected {
  background-color: #196773 !important;
  color: #fff !important;
  border-radius: 10px !important;
  width: 200px !important;
}

.stack {
  align-items: center;
  gap: 8px;
}
.grid-table {
  width: 100% !important;
  overflow: auto !important;
  position: sticky !important;
  top: 0 !important;
  width: 100% !important;
  min-width: 960px !important;
  /* padding-bottom: 70px !important; */

  /* z-index: 1 !important; */
}

.grid-table1 {
  width: 100% !important;
  overflow: auto !important;
  position: sticky !important;
  top: 0 !important;
  width: 100% !important;
  min-width: 960px !important;
  /* padding-bottom: 70px !important; */

  /* z-index: 1 !important; */
}

.my-list {
  /* list-style-type: disc !important; */
  width: 100% !important;
  display: block !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  margin-left: 4px;
  color: #27364b !important;
  text-align: center; 
}
.my-list li {
  display: block;               
}
.font {
  font-size: 10px !important;
}

.my-list-w {
  /* list-style-type: disc !important; */
  display: block;
  text-align: start;
  overflow: hidden;
}

.Questions-tabs {
  border: 1px solid lightgray !important;
  border-radius: 10px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  color: #27364b !important;
  background: #f6f8fc !important;
  font-weight: bold !important;
  text-transform: inherit !important;
  max-width: 235px !important;
  width: 100% !important;
}

.Vertical-tabs {
  border: 1px solid lightgray !important;
  border-radius: 10px !important;
  /* margin-left: 10px !important; */
  margin-right: 10px !important;
  color: #27364b !important;
  background: #f6f8fc !important;
  font-weight: bold !important;
  text-transform: inherit !important;
  /* max-width: 235px !important; */
  /* width: 100% !important; */
}

.form-head {
  font-size: 20px !important;
}

.add-question {
  color: #196773 !important;
  border: 1px solid #196773 !important;
  margin-bottom: 22px !important;
  margin-top: 22px !important;
  text-transform: inherit !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
  font-weight: 600 !important;
}

.add-question:hover {
  background-color: #fae7e2 !important;
  border-color: #f26539 !important;
  border: 1px solid #f26539;
  color: #f26539 !important;
}

.select-btn {
  padding: 0 !important;
  align-items: flex-start !important;
  color: #27364b !important;
  font-weight: bold !important;
}

.add-icon {
  border: 1px solid #cbd4e1 !important;
  font-size: 25px !important;
  background: #f6f8fc !important;
  border-radius: 5px !important;
}

.list-form {
  margin: 0px 0px 15px 0px !important;
  color: #27364b;
}

.validations-title {
  margin: 0;
  margin-right: 5px;
}

.Validations-box {
  display: flex !important;
  margin: 0px 4px !important;
  border: 1px solid #cbd4e1 !important;
  border-radius: 20px !important;
  padding: 1px 10px 0px !important;
  background-color: #ffffff !important;
  font-size: 11px !important;
  color: #27364b;
  width: fit-content !important;
}

.video-validation {
  color: #f26539;
}
.validation-type {
  font-size: 14px;
}

.validation-div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.validation-div2 {
  display: flex;
  align-items: center;
}

.validation-div3 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.validation-stack {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-box {
  display: grid;
  align-items: center;
  gap: 10px;
}

.total-issue {
  color: #64748b !important;
}

.priroty-table > tbody > tr > td:last-child {
  padding: 10px !important;
  margin: 0 !important;
  border: 0 !important;
  text-align: left !important;
  color: #27364b !important;
}

.priroty-table > tbody > tr > td:first-child {
  padding: 10px !important;
  margin: 0 !important;
  border: 0 !important;
  text-align: right !important;
  color: #27364b !important;
}
.priroty-table {
  display: flex !important;
  justify-content: start;
  overflow-x: auto;
}
.Priority {
  border: 1px solid rgb(245, 101, 69);
  border-radius: 30px;
  padding: 3px 15px 3px 15px;
  margin: 0 10px 0px 0px !important;
  color: rgb(245, 101, 69);
}

.Medium {
  border: 1px solid #f39a4c;
  border-radius: 30px;
  padding: 3px 15px 3px 15px;
  margin: 0 10px 0px 0px !important;
  color: #f39a4c;
}

.low {
  border: 1px solid #1465b4;
  border-radius: 30px;
  padding: 3px 15px 3px 15px;
  margin: 0 10px 0px 0px !important;
  color: #1465b4;
}

.NonCritical {
  border: 1px solid rgb(153, 254, 4);
  border-radius: 30px;
  padding: 3px 15px 3px 15px;
  margin: 0 10px 0px 0px !important;
  color: rgb(148, 182, 96);
}

.Critical {
  border: 1px solid rgb(250, 2, 3);
  border-radius: 30px;
  padding: 3px 15px 3px 15px;
  margin: 0 10px 0px 0px !important;
  color: rgb(250, 2, 3);
}

.Open {
  border: 1px solid #039855;
  border-radius: 30px;
  padding: 3px 15px 3px 15px;
  margin: 0 10px 0px 0px;
  color: #039855;
}

.InProgress {
  border: 1px solid rgb(54, 185, 204);
  border-radius: 30px;
  padding: 3px 15px 3px 15px;
  margin: 0 10px 0px 0px;
  color: rgb(54, 185, 204);
}

.Resolved {
  border: 1px solid rgb(6, 251, 6);
  border-radius: 30px;
  padding: 3px 15px 3px 15px;
  margin: 0 10px 0px 0px;
  color: rgb(6, 251, 6);
}

.Closed {
  border: 1px solid rgb(250, 2, 3);
  border-radius: 30px;
  padding: 3px 15px 3px 15px;
  margin: 0 10px 0px 0px;
  color: rgb(250, 2, 3);
}

.icon-button {
  height: 0px !important;
}

.attachment-file {
  border: 1px solid #cbd4e1 !important;
  border-radius: 8px !important;
  padding: 10px !important;
  display: flex !important;
}

.icon-upload {
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  border: 1px solid #196773 !important;
}

.image-multi {
  width: 50px !important;
  height: 50px !important;
  margin: 0 0px 0 10px !important;
}

.chat-card {
  background-color: #f6f8fc !important;
  padding: 10px 0px 10px 0px;
  margin: 0px 15px 10px 15px;
}

.chat-list {
  list-style-type: none !important;
  margin: 5px 0px !important;
  padding-left: 5px !important;
}

.chat-box {
  text-align: left !important;
  margin: 0px 10px 0px 20px;
}

.chat-msg {
  border: 1px solid #cbd4e1;
  border-radius: 8px;
  background-color: #d6f3e9;
  padding: 8px;
}

.chat-msg-any {
  border: 1px solid #cbd4e1;
  border-radius: 8px;
  background-color: #fff;
  padding: 8px;
}

.chat-time {
  text-align: center;
  color: grey;
  font-size: 12px;
  margin: 5px;
}

.reply-btn {
  width: 100% !important;
  background: #196773 !important;
  height: 100% !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border-radius: 8px !important;
}

.Author {
  border: 1px solid #cbd4e1;
  border-radius: 10px;
  background-color: #ffffff;
  width: fit-content;
  padding: 0px 10px;
  text-transform: capitalize;
}

.Login-form {
  background-image: url("./images/Login\ BG.png");
  height: 100vh !important;
  background-repeat: no-repeat !important;
  text-align: left;
  background-color: #e2e8f0 !important;
  display: flex !important;
  margin: 0 auto !important;
  align-items: center !important;
}

.label-name {
  color: #196773 !important;
  margin-bottom: 5px !important;
}

.login-button {
  width: 100% !important;
  background-color: #f26539 !important;
  color: #fff !important;
  padding: 10px !important;
  border-radius: 8px !important;
  text-transform: inherit !important;
}

.login-futter {
  margin: 16px 0 0 0 !important;
  align-items: center !important;
  /* justify-content: space-between !important; */
  display: flex !important;
}

.forgot-link {
  text-align: center !important;
  color: #bb251a !important;
  text-decoration: none !important;
}

.step1-box {
  margin: 40px 10px 0px 10px !important;
  text-align: center !important;
}

.email-box {
  font-weight: bold !important;
  color: #64748b !important;
  margin: 0px 0 20px 0px !important;
}

/* .verif-code > div {
  width: 100vh !important;
} */

.graph-btn {
  display: flex !important;
  justify-content: space-between !important;
}

.verif-code > div > input {
  border: none !important;
  border-bottom: 2px solid grey !important;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 0 !important;
  width: 30px !important;
}

.rnc {
  display: flex !important;
  flex-direction: row !important;
  border-radius: 6px !important;
  padding: 0px !important;
  background-color: #fff !important;
  justify-content: space-between !important;
  max-width: none !important;
}

.rnc-row {
  border: 1px solid darkgrey !important;
  border-radius: 8px !important;
  padding: 5px !important;
}

.rnc-input {
  height: auto !important;
  width: 100% !important;
  border: 1px solid darkgrey !important;
  margin: 0px 0px 13px 16px !important;
  border-radius: 8px !important;
}

.box-error > div > div > input {
  border: 1px solid #d32f2f !important;
  border-radius: 8px !important;
}

.error-msg {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.8rem;
  margin-top: 10px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 14px;
}

.profile-menu {
  padding-left: 120px;
  display: none !important;
}
.subRole {
  display: none !important;
  padding-top: 5px !important;
}

.edit-hover {
  border: 1px solid #cbd4e1 !important;
  border-radius: 7px !important;
  background-color: #f6f8fc !important;
  font-size: 30px !important;
  cursor: pointer !important;
  padding: 4px !important;
}

.edit-hover:hover {
  color: #fff !important;
  background: #f39a4c !important;
  border-color: #f39a4c !important;
}

.delete-hover {
  border: 1px solid #cbd4e1 !important;
  border-radius: 7px !important;
  background-color: #f6f8fc !important;
  font-size: 30px !important;
  cursor: pointer !important;
  padding: 4px !important;
}

.delete-hover:hover {
  color: #fff !important;
  background: #bb251a !important;
  border-color: #bb251a !important;
}

.map-hover {
  border: 1px solid #cbd4e1 !important;
  border-radius: 5px !important;
  background-color: #f6f8fc !important;
  margin: 5px !important;
  font-size: 30px !important;
  padding: 3px !important;
}

.map-hover:hover {
  color: #fff !important;
  background-color: #3498db !important;
  border-color: #3498db !important;
}

.iconbtn {
  padding: 8px !important;
  border-radius: 50% !important;
}

.input-fields {
  margin-bottom: 10px !important;
}

.input-fields > div {
  border-radius: 8px !important;
}

.issueinput-fields > div {
  border-radius: 8px !important;
}

.multiselect-map {
  width: 100% !important;
  border-radius: 8px !important;
}

.modal-size > div > div {
  width: 100% !important;
  max-width: 800px !important;
}

.modal-size-subcategory > div > div {
  width: 100% !important;
  max-width: 500px !important;
}

.select-width {
  width: 20% !important;
}

.answer-select > div {
  padding: 5px !important;
}

.select-issues {
  border: 1px solid lightblue !important;
  color: #4e73df !important;
  border-color: #4e73df !important;
  font-size: 12px !important;
  text-transform: inherit !important;
  padding: 6px 12px !important;
  border-radius: 8px !important;
}

.rulesmodel-lebel {
  color: #5a5c69 !important;
  display: inline-block !important;
  padding-top: 0.5rem !important;
}

.delete-icons {
  justify-content: space-between !important;
  align-items: center !important;
}

/* .arrow-icon {
  margin-top: 12px;
} */

.list-view {
  border: 1px solid darkgrey;
  padding: 0px 24px 0px 24px;
  margin: 10px 0 10px 0;
  border-radius: 8px;
}

.third-list {
  align-items: center !important;
  white-space: nowrap !important;
}

.role-user-tab > div {
  padding: 0 !important;
}

.viewchecklist {
  padding: 8px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  background: #fff;
}

.percent-rate {
  font-size: 25px !important;
  font-weight: 600 !important;
}

.graph-font {
  font-weight: 600 !important;
  font-size: 13px;
  margin-right: 26px !important;
}

.donut-chart > div > svg > foreignObject > div {
  gap: 15px;
}

.donut-chart > div > svg > foreignObject > div > div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.create-new-form {
  display: flex !important;
  gap: 8px;
}

.Assign-Checklist {
  float: right !important;
  gap: 8px !important;
  height: 42px;
}
.customer-log-header {
  float: right !important;
  gap: 8px !important;
}
.nowrap-time {
  white-space: nowrap;
}

.validation-maindiv {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}

.dash-piechart {
  overflow: auto !important;
  display: flex !important;
  justify-content: center !important;
}

#ReactSimpleImageViewer {
  z-index: 9999 !important;
}

.graph-card {
  min-height: 260px !important;
}

.progress-bar {
  color: #27364b !important;
}

.profilemodal {
  color: #5a5c69;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.4rem;
  margin: 0.125rem 0 0;
  min-width: 10rem;
  z-index: 1000;
  width: 20rem;
  position: absolute;
  top: 50px;
  right: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%) !important;
}

.notificationmodal {
  top: 50px;
  right: 200px;
  position: absolute;
  width: 20rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%) !important;
  z-index: 1000;
  min-width: 10rem;
  margin: 0.125rem 0 0;
  font-size: 0.85rem;
  color: #5a5c69;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.4rem;
}

.notification-head {
  background-color: #113644;
  border: 1px solid #113644;
  color: #fff;
  text-transform: uppercase !important;
  border-radius: 0.4rem;
  padding: 0.5rem 1.5rem;
  font-weight: 800;
  font-size: 0.65rem;
}

.profile-avatar {
  background: #f6f8fc !important;
  width: 80px !important;
  height: 80px !important;
  border: 1px solid #cbd4e1 !important;
  color: #27364b !important;
  padding: 18px;
  margin-bottom: 1rem;
}

.account-btn {
  box-shadow: 0 0.125rem 0.25rem 0 rgb(58 59 69 / 20%) !important;
  border-radius: 50rem !important;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #3a3b45;
  background-color: #f8f9fc;
  border-color: #f8f9fc;
  cursor: pointer;
}

.account-btn:hover {
  color: #3a3b45;
  background-color: #dde2f1;
  border-color: #d4daed;
}

.change-company-div {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f8f9fc;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  width: 100%;
  border-left: 0;
  border-right: 0;
}

.change-company-btn {
  text-align: left !important;
  display: block !important;
  padding: 6px 16px !important;
  color: inherit !important;
  text-transform: inherit !important;
}

.change-company-btn:hover {
  background-color: transparent !important;
}

.profile-avatar2 {
  background: #f6f8fc !important;
  width: 40px !important;
  height: 40px !important;
  border: 1px solid #cbd4e1 !important;
  color: #27364b !important;
}

.signout-btn {
  box-shadow: 0 0.125rem 0.25rem 0 rgb(58 59 69 / 20%) !important;
  border-radius: 0.2rem !important;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #3a3b45;
  background-color: #f8f9fc;
  border-color: #f8f9fc;
  cursor: pointer;
}

.signout-btn {
  background: #4e73df !important;
  border-radius: 8px !important;
  padding: 0px 5px 0px 5px !important;
  color: #fff !important;
}

.check-graph {
  height: 100%;
}

.store-question {
  background: #4e73df;
  /* border: 1px solid red; */
  border-radius: 8px;
  padding: 0px 5px 0px 5px;
  color: #fff;
}

.list-items-fields > div > input {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.8) !important;
}

.training-date > div {
  border-radius: 8px !important;
  height: 40px !important;
}

.add-lists-training {
  text-align: left;
  border: 1px solid lightgrey;
  border-radius: 8px;
  margin-bottom: 20px;
}

.assesment-list {
  align-items: center !important;
  color: #fff;
  background-color: #4e73df;
  border: 1px solid #e3e6f0 !important;
  border-radius: 2rem;
  display: flex;
  justify-content: space-between;
  padding: 3px 10px 3px 10px;
}

.assesment-list-hover {
  margin: 7px;
  border: 1px solid #cbd4e1;
  border-radius: 8px;
  width: 100%;
}

.assesment-list-hover-selected {
  margin: 7px;
  border: 1px solid #cbd4e1;
  border-radius: 8px;
  width: 100%;
  background: #1976d2;
  color: #fff;
}

.assesment-list-hover:hover {
  margin: 7px;
  border: 1px solid #cbd4e1;
  border-radius: 8px;
  color: #4e73df;
  width: 100%;
  cursor: pointer;
}

.assesment-list-hover-selected:hover {
  margin: 7px;
  border: 1px solid #cbd4e1;
  border-radius: 8px;
  color: #fff;
  width: 100%;
  cursor: pointer;
  background-color: #64a8eb;
}

.box-icons {
  display: flex !important;
  justify-content: center !important;
  align-items: baseline !important;
}

.content-training {
  background-color: #fff !important;
  box-shadow: none !important;
  display: flex !important;
}

.content-training-box:hover {
  background-color: #113644 !important;
  border: 1px solid #f26539 !important;
  color: #fff !important;
}

.content-training-box {
  border: 1px solid #cbd4e1 !important;
  border-radius: 8px !important;
  padding: 10px !important;
  display: flex !important;
  justify-content: center !important;
  color: #5a5c69;
}

.content-image-box {
  border: 1px solid #cbd4e1 !important;
  border-radius: 8px !important;
  padding: 10px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.edit-image {
  border-color: #f4b619;
  color: #f4b619;
  border: 1px solid darkgrey;
  border-radius: 4px;
  background: aliceblue;
}

.edit-image:hover {
  color: #fff;
  background-color: #f4b619;
  border-color: #f4b30d;
  border-radius: 4px !important;
}
.submission-header-stack {
  gap: 5px;
}
.submission-header-grid {
  display: flex;
  justify-content: end;
  align-items: center;
}
.card-height {
  height: 100%;
  border-radius: 8px !important;
}
.line-chart-height {
  margin: 5px;
  height: 300px;
  overflow: scroll;
  padding: 10px;
}
.rnc-canvas {
  width: -webkit-fill-available !important;
  max-width: 200px !important;
}
.training-list {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
}
/* MEDIA QUERIES START */

@media screen and (max-width: 426px) {
  .check-graph {
    padding: 8px !important;
  }
  .imagelog-grid-padding {
    padding: 10px !important;
  }
  .individual-modal {
    max-width: calc(100% - 64px) !important;
  }
  .user-header {
    width: 100% !important;
  }
  .userlist-header-btn {
    flex-direction: column !important;
  }
  .rnc {
    display: inherit !important;
  }
  .rnc-input {
    margin: 0 !important;
    padding: 11px 11px !important;
    width: -webkit-fill-available !important;
  }
  .submission-header-grid {
    justify-content: center !important;
  }
  .to-box {
    display: flex;
    justify-content: center !important;
  }
  .survey-date-div {
    display: grid !important;
  }
  .submission-header-stack {
    flex-direction: column !important;
  }
  .stack {
    display: grid !important;
  }
  .date-pick {
    align-items: center;
  }
  .pathname {
    margin: 0 !important;
    font-size: 15px !important;
    text-align: left !important;
  }

  .button-responsive {
    width: 100% !important;
    margin: 10px 0px 10px 0px !important;
  }

  .file-preview {
    margin: 0px 0px 0px 10px !important;
  }

  .display {
    display: block !important;
  }

  /* .tabs-content-parent > div > input:nth-child(2) > div {
    display: grid !important;
  } */

  .tabs-content-parent > div > div {
    display: grid !important;
    gap: 8px !important;
  }

  .Assign-Checklist {
    display: grid !important;
    width: 100% !important;
  }
  .customer-log-header {
    display: grid !important;
    width: 100% !important;
  }
  .date-content {
    display: grid !important;
    width: 100% !important;
    margin: 0 auto !important;
    align-items: center !important;
    text-align: center !important;
  }

  .header-add {
    width: 100% !important;
    height: 42px !important;
  }

  .validation-div {
    display: grid !important;
    gap: 5px !important;
  }

  .dash-select-overview {
    max-width: 100% !important;
  }

  .checkbox {
    display: block !important;
  }
  .datalist-header-stack {
    flex-direction: column !important;
  }
  .profile-menu {
    display: block !important;
  }
  .subRole {
    display: block !important;
  }

  .header-manage {
    display: none !important;
  }

  .avtar-icon {
    width: 30px !important;
    height: 30px !important;
  }

  .header-images {
    width: 30px !important;
  }

  .main-header > div {
    display: flex !important;
  }

  .notify-text {
    top: 5px !important;
    font-size: 8px !important;
  }

  .notify-text-company {
    top: 5px !important;
    font-size: 8px !important;
  }

  /* .header-card-n {
    margin-top: 0px !important;
    overflow: auto !important;
    top: 0 !important;
    
  }

  .header-card-n {
    margin-top: 50px !important;
    padding: 8px !important;
    background-color: #f6f8fc !important;
  }

  .header-card-n {
    padding: 8px;
    background-color: #f6f8fc !important;
    border-radius: 8px !important;
  } */

  .header-card {
    margin-top: 0px !important;
    overflow: auto !important;
    /* position: sticky !important; */
    top: 0 !important;
    /* width: 100% !important; */
    /* min-width: 960px !important;
  padding-bottom: 70px !important; */

    /* z-index: 1 !important; */
  }

  .header-card-user {
    height: 70px !important;
  }
  .issue-card {
    margin-top: 0px !important;
  }
  .search-bar > :nth-child(2) {
    width: 100% !important;
  }
  .checklist-report-header {
    flex-direction: column !important;
    width: 100%;
  }
  .done-btn-storeinfo {
    width: 100% !important;
  }
  .submenu-item-storeinfo {
    width: 100%;
  }
  .location-storeinfo {
    width: 100% !important;
  }
}

@media screen and (max-width: 470px) {
  .checkbox {
    font-size: 12px !important;
  }

  .subrolestack {
    display: grid !important;
    justify-content: center !important;
    float: none !important;
    gap: 10px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 376px) {
  /* .min-card {
    padding-right: 10px;
  } */

  .graph-card {
    padding: 0 !important;
  }

  .box-space {
    padding: 15px !important;
  }

  .apply {
    margin: 0 !important;
  }

  .zero-space {
    padding: 0 !important;
  }

  .text-content {
    margin-left: auto !important;
    line-height: normal !important;
  }

  .table-group {
    display: table-column-group !important;
  }

  .graph-font {
    margin-left: -20px !important;
    font-size: 15px !important;
  }

  .store-head {
    width: max-content !important;
    font-size: smaller !important;
  }

  .due-check {
    padding: 0 !important;
  }

  .percent-rate {
    font-size: 30px !important;
    margin-left: 15px !important;
  }

  .header-padding {
    padding: 40px !important;
  }

  .check-graph {
    padding: 8px !important;
  }

  .calendar {
    margin: -10px !important;
  }

  .progress-bar {
    min-width: max-content !important;
    margin-left: -80px !important;
    margin-top: 15px !important;
  }

  .back-btn {
    display: block !important;
    padding-bottom: 10px !important;
  }

  .logo-img {
    margin: auto !important;
    display: block !important;
  }

  .course-area {
    width: max-content;
    font-size: small;
  }

  .header-item {
    width: 25px !important;
    margin: 0px 5px 0px 5px !important;
  }

  .responsive-header {
    display: contents !important;
  }

  .navigation {
    font-size: 10px !important;
  }

  .checkbox {
    font-size: 10px !important;
  }

  .header-add-filter {
    display: flex !important;
    width: 100%;
  }

  /* .header-add {
    font-size: 14px !important;
  } */

  .table-container {
    overflow-x: scroll !important;
  }

  .menuItems {
    padding: 0px 30px 0px 0px !important;
  }

  .details-table {
    display: block !important;
    overflow-x: auto !important;
  }

  .details-table-po {
    display: block !important;
    overflow-x: auto !important;
    justify-content: center !important;
    max-width: 100% !important;
    display: flex !important;
  }

  .details-table > tbody {
    display: block !important;
  }

  .button-tabs {
    margin: 0px 0px 20px 0px !important;
  }

  /* .Questions-tabs.Mui-selected {
    width: 100% !important;
  } */

  .Questions-tabs {
    width: 100% !important;
  }

  .create-new-form {
    float: left !important;
    margin-top: 10px !important;
  }

  .add-question {
    margin-left: 0 !important;
  }

  .Select-kpi {
    display: block !important;
  }

  .plus-icon {
    float: left !important;
    margin-top: 5px;
  }

  .extra-div {
    display: none !important;
  }

  .list-form {
    display: block !important;
  }

  .list-style {
    padding: 10px !important;
  }

  .Validations-box {
    margin: 10px 10px 10px 0px !important;
  }

  .graph-content {
    display: block !important;
    margin: 10px 0px 10px 0px !important;
    width: 100% !important;
  }

  .stack-align {
    text-align: left !important;
    padding: 0 !important;
  }

  .colomn-padding {
    padding-top: 20px !important;
  }

  .header-add-filter {
    margin-left: 0 !important;
  }

  .search-float-bar {
    float: left !important;
    width: 100% !important;
  }

  .signup-form {
    padding: 0 !important;
  }

  .Login-form {
    height: 100% !important;
  }
}

@media screen and (max-width: 992px) {
  /* .profile-menu {
    display: none !important;

  }

  .header-manage {
    display: none !important;
  } */

  .line-chart-btns1 {
    display: grid !important;
    gap: 5px !important;
  }
  .mobile-sidedrawer {
    display: none;
    z-index: 999 !important;
  }

  .dasboad-main-content {
    padding: 40px 5px 22px 5px !important;
  }
  .lms-report-card {
    display: block !important;
  }
  .Checklist-Detail-direction {
    flex-direction: column !important;
    text-align: start !important;
  }
}

@media screen and (max-width: 899px) {
  .image-data-stack {
    flex-direction: column !important;
    text-align: start;
    gap: 5px !important;
  }
  .divider-line {
    border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
    height: 16px;
    position: absolute;
    left: 50%;
  }
  .graph-card-content {
    display: block !important;
  }

  .vl {
    display: none !important;
  }
  .audit-header {
    flex-direction: column !important;
    gap: 5px;
  }
  .bg {
    width: 100% !important;
  }
  .header-select-stack {
    width: 100%;
  }
  .Assessment-Ques-grid {
    padding-left: 8px !important;
  }
}
@media screen and (max-width: 520px) {
  .create-assessment-header {
    flex-direction: column !important;
    width: 100%;
  }
  .user-img-div {
    flex-direction: column !important;
    align-items: start !important;
  }
  .allform-head-time {
    flex-direction: column !important;
  }
}
@media screen and (max-width: 599px) {
  .userdetail-header-grid {
    margin-top: 10px !important;
  }
  .available-head {
    flex-direction: column !important;
  }
}
@media screen and (min-width: 374px) and (max-width: 800px) {
  .available-head {
    flex-direction: column !important;
  }
  .date-content {
    display: block !important;
    justify-content: center !important;
    width: 100% !important;
  }

  .details-table {
    overflow-x: auto !important;
  }

  .tabs-content-parent > div > div {
    display: grid !important;
    gap: 8px !important;
  }

  .table-container {
    overflow-x: scroll !important;
  }

  .validation-div {
    display: grid !important;
    gap: 5px !important;
  }

  .card-list {
    /* width: fit-content !important; */
    line-height: normal !important;
  }

  .text-center {
    justify-content: center !important;
  }

  .Assign-Checklist {
    float: left !important;
  }
  .Assign-Checklist-filter {
    float: right !important;
    margin-top: 20px;
  }
  /* .dasboad-main-content {
    margin-top: 8% !important;
  } */

  .date-content {
    float: left !important;
    margin-top: 10px !important;
  }

  .button-tabs {
    margin: 0px 0px 20px 0px !important;
  }

  .extra-div {
    display: none !important;
  }

  .list-form {
    display: block !important;
  }

  .tablet-view {
    display: block !important;
  }

  .value-btn {
    align-items: baseline !important;
  }

  .colomn-padding {
    padding-top: 20px !important;
  }
}

@media screen and (max-width: 1400px) {
  .graph-btn {
    display: grid !important;
    gap: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .validation-div2 {
    display: grid !important;
    gap: 5px !important;
  }

  .main-header > div {
    display: block;
  }

  .dasboad-main-content {
    margin-top: 110px;
  }

  .notify-text {
    top: 65px;
  }

  .notify-text-company {
    top: 35px;
  }

  .header-card1 {
    background-color: #f6f8fc !important;
    padding: 8px !important;
    margin-top: 50px;
  }

  .header-card {
    margin-top: 50px;
    padding: 8px !important;
    background-color: #f6f8fc !important;
  }
  .issue-card {
    margin-top: 50px;
  }
  .pathname {
    margin-bottom: 10px !important;
  }
  .userlist-head-maingrid {
    flex-direction: column !important;
  }
  .userlist-head-grid2 {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 1300px) {
  .validation-maindiv {
    display: grid !important;
    gap: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .Rules-preset-form {
    width: 100% !important;
  }

  .details-table {
    overflow-x: auto !important;
    display: flex !important;
  }

  .list-btn {
    display: block !important;
  }

  .Validations-box {
    margin: 10px 10px 10px 0px !important;
  }

  .date-content {
    float: left !important;
    margin-top: 10px !important;
  }

  .max-width {
    width: max-content !important;
    line-height: normal !important;
  }

  .button-tabs {
    margin: 0px 0px 20px 0px !important;
  }

  .zero-padding {
    padding: 0 !important;
  }

  /* 
  .minus-margin {
    margin: -20px !important;
  } */

  .top-margin {
    margin-top: 20px !important;
  }

  .validation-stack {
    display: grid !important;
    gap: 10px !important;
  }

  .validation-div3 {
    display: grid;
  }

  .img-box {
    display: flex !important;
  }

  .line-chart-box {
    display: grid !important;
    gap: 8px !important;
    justify-content: start !important;
  }
}

/* MEDIA QUERIES END */
@page {
  size: auto;
  margin: 0mm 40mm;
}

body {
  font-family: "Inter", sans-serif;
  color: #27364b !important;
}

.SfxModal-Wrapper {
  z-index: 9999 !important;
}

.header-side1 {
  border-radius: 10px;
  background-color: #64748b;
  color: #ffffff;
  width: 120px;
  height: 4vh;
  padding: 5px 12px;
  margin: 2px 3px;
}

.fit-content {
  width: fit-content !important;
}

.header-side1-inner {
  width: 40px;
  height: 24px;
  background-color: #ffffff;
  position: absolute;
  border-radius: 20px;
  color: #f26539;
  padding: 2px 6px;
  margin: 1px 25px;
  text-align: center;
}

.header-side2 {
  border-radius: 10px;
  background-color: #ffffff;
  color: #64748b;
  width: 120px;
  height: 4vh;
  padding: 5px 12px;
  margin: 2px 1px;
  border: 2px solid #64748b;
}

.header-side2-inner {
  width: 40px;
  height: 24px;
  background-color: #f6f8fc;
  position: absolute;
  border-radius: 20px;
  color: #64748b;
  padding: 2px 4px;
  margin: 1px 18px;
  text-align: center;
}

.header-card {
  padding: 8px 0;
  background-color: #f6f8fc !important;
  border-radius: 8px !important;
}

.bg {
  background-color: #ffffff !important;
}

.header-add {
  border-radius: 8px !important;
  background-color: #196773 !important;
  color: #ffffff !important;
  padding: 5px 12px !important;
  border: 1px solid transparent !important;
  text-transform: capitalize !important;
  height: 45px;
}

.export-btn-lms-report {
  height: 40px;
}

.date-pick > div {
  border-radius: 8px !important;
  height: 40px;
  width: 150px;
}

.date-picker-manual > div {
  border-radius: 8px !important;
  height: 40px;
  width: 100%;
}

.date-pick-submision > div {
  width: 190px;
}

.date-pick-overview > div {
  width: 190px;
}

.checklist-filter .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: 45px;
}

.apexcharts-datalabels {
  opacity: 0.9 !important;
}
.list-images-form {
  display: flex;
  align-items: start;
  gap: 10px;
}

.list-images-form img {
  width: 130px;
  height: 100px;
}

.list-image-wrapper {
  margin-top: 15px;
}

/* .delete-container {
  position: absolute;
  bottom: 5px;
  background-color: rgba(0,0,0,.5);
  border-radius: 3px;
  display: flex;
  right: 5px;
  position: absolute;
  z-index: 10;
  cursor: pointer;
} */

.delete-container {
  position: absolute;
  top: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  display: flex;
  right: 5px;
  z-index: 10;
  cursor: pointer;
}
.kyc-delete-container {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  display: flex;
  right: 5px;
  z-index: 10;
  cursor: pointer;
}

.delete-container:hover::before {
  content: "Delete";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 4px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
}

.date-pick > div > fieldset {
  border-color: #64748b !important;
  align-items: center;
  display: flex;
}

.date-pick > label {
  color: #64748b !important;
  align-items: center;
  display: flex;
  margin-top: -7px;
}

.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
  margin-left: 20px !important;
}

.header-button {
  border-radius: 8px !important;
  background-color: #196773 !important;
  color: #fff !important;
  padding: 5px 12px !important;
  border: 1px solid #64748b !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  height: 45px;
}

.header-add-filter {
  border-radius: 10px !important;
  padding: 12px 16px !important;
  border-color: #196773 !important;
  color: #196773 !important;
  border: 1px solid #64748b !important;
  height: 50px;
  text-transform: inherit !important;
}

.header-add-filter > span {
  gap: 8px !important;
}

.cancle-button {
  border-radius: 8px !important;
  padding: 5px 12px !important;
  border-color: #196773 !important;
  border: 1px solid #64748b !important;
  height: 45px;
  text-transform: capitalize !important;
  color: #27364b !important;
}

.header-search {
  border-radius: 10px;
  background-color: #ffffff;
  color: #64748b;
  width: 80px;
  height: 5vh;
  padding: 7px 12px 2px 0px;
  border: 2px solid #cbd4e1;
  text-align: end;
}

.switch-button {
  border-radius: 10px;
  background-color: #ffffff;
  color: #27364b;
  height: 5vh;
  border: 2px solid #cbd4e1;
  position: relative;
  align-items: center;
  display: flex;
  padding: 5px 10px 5px 0;
}

.delete-button {
  border-radius: 10px;
  background-color: #ffffff;
  color: #bb251a;
  width: 80px;
  height: 4vh;
  border: 2px solid #bb251a;
  text-align: end;
  padding: 10px 20px 0px 0px;
}

.edit-button {
  border-radius: 10px;
  background-color: #f39a4c;
  color: #ffffff;
  width: 80px;
  height: 4vh;
  border: 2px solid #f39a4c;
  text-align: end;
  padding: 10px 20px 0px 0px;
}

.card {
  border: 1px solid #cbd4e1 !important;
  border-radius: 8px !important;
}
.usercard {
  border: 1px solid #f39a4c;
  border-radius: 8px !important;
  box-shadow: 0px 3px 4px rgba(9, 30, 66, 0.4),
    0px 0px 1px rgba(9, 30, 66, 0.31) !important;
}
.complete-button {
  border-radius: 10px;
  background-color: #196773;
  color: #ffffff;
  width: 80px;
  height: 4vh;
  border: 2px solid #196773;
  text-align: end;
  padding: 10px 20px 0px 0px;
}

/* sx={{ px: 6, py: 1, ml: 1 }} */
.company-name-btn {
  background-color: #f26539 !important;
  margin-left: 5px !important;
  padding: 10px 50px !important;
  text-decoration: none !important;
}

.company-name-btn:hover {
  background-color: #f26539 !important;
}

.company-btn {
  background-color: #f26539 !important;
  margin-right: 10px !important;
}

.company-btn:hover {
  background-color: #f26539 !important;
}

.company-create-btn {
  background-color: #f26539 !important;
  margin-right: 90px !important;
  padding: 10px 50px !important;
}

.dash-message {
  border-radius: 8px !important;
  padding: 25px 16px !important;
  margin-top: 5px !important;
  border-color: #cbd4e1 !important;
  justify-content: start !important;
}

.dash-message > span {
  font-size: 14px;
  color: #64748b;
}

.message-link {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 20px !important;
  color: #64748b !important;
  text-decoration-color: #64748b !important;
}

.message-link-2 {
  text-decoration: none !important;
  display: flex;
  /* margin: 20px 10px !important; */
  color: #64748b !important;
  text-decoration-color: #64748b !important;
  padding-top: 15px;
  font-size: 14px;
  font-weight: 600;
}

.message-link-3 {
  color: #64748b !important;
  text-decoration: none !important;
  line-height: 35px !important;
  display: flex !important;
  margin: 15px 55px !important;
  font-size: 14px;
  font-weight: 600;
}

.message-link-4 {
  color: #64748b !important;
  text-decoration: none !important;
  line-height: 30px !important;
  display: flex !important;
  margin: 10px 0px !important;
  font-size: 14px;
  font-weight: 600;
  width: fit-content !important;
}

.dash-select {
  border: 1px solid #cbd4e1;
  height: 32px;
  width: 130px;
  border-radius: 8px;
  padding: 4px 16px;
  color: #27364b;
  font-weight: 600;
  display: flex;
}

.check-select {
  border: 1px solid #cbd4e1;
  height: 35px;
  width: fit-content;
  border-radius: 8px;
  padding: 4px 16px;
  color: #27364b;
  font-weight: 600;
  display: block;
  margin-top: 32px;
}

.select1 {
  background: url("../assets/icons/svg-icons/Down.svg");
  appearance: none;
  background-repeat: no-repeat;
  background-position: right;
  width: 100px;
}

.select2 {
  background: url("../assets/icons/svg-icons/Down.svg");
  appearance: none;
  background-repeat: no-repeat;
  background-position: right;
  width: fit-content;
  padding-right: 30px;
  margin: 0px !important;
}

.enable-toggle > div > label {
  margin: 0px !important;
  justify-content: center;
}

.box-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .calendar > div {
  height: 100%;
  width: 100%;
  box-shadow: none;
} */
.calender1 > div > div {
  width: 100%;
  padding: 15px;
}

.list-data {
  padding: 0px 16px !important;
  border: 1px solid #cbd4e1 !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  width: 100% !important;
  margin-top: 0px !important;
}

.list-data > li > div > span {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #27364b !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.overview-ul {
  padding: 0px 0px !important;
}

.list-data > li > div > p {
  color: #64748b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.box-container2 {
  align-items: center;
  justify-content: space-between;
}

.box-container1 {
  align-items: center;
  justify-content: space-between;
  height: 270px !important;
}

.graph-card-content {
  display: flex;
  padding: 16px !important;
}

.vl {
  border-left: 1px solid lightgray;
  height: 180px;
  margin: 35px -25px 0px 10px;
}

.due-check {
  gap: 6px !important;
}

.heading {
  color: #64748b;
  font-size: 18px;
}

.message-avatar {
  background: #f6f8fc !important;
  width: 35px !important;
  height: 35px !important;
  border: 1px solid #cbd4e1 !important;
  color: #27364b !important;
  padding: 8px;
  margin: 0px 0px 0px 5px;
}

.avatar-image {
  background: #f6f8fc !important;
  width: 55px !important;
  height: 55px !important;
  border: 1px solid #cbd4e1 !important;
  color: #27364b !important;
  margin: auto;
}

.company-edit {
  float: right;
  width: 20px;
  cursor: pointer;
}

.tableHead {
  font-weight: 600 !important;
  color: #27364b !important;
  white-space: nowrap !important;
}

.iconBox {
  border: 1px solid #cbd4e1;
  background: inherit;
  width: 35px;
  height: 35px;
  padding: 5px;
  border-radius: 8px !important;
  cursor: pointer;
  color: #27364b;
  background: #f6f8fc;
}

.iconBox:hover {
  border: 1px solid #1465b4;
  background: #1465b4;
}

.changePwdIcon {
  border-radius: 8px !important;
  width: fit-content !important;
  height: 35px !important;
  justify-content: flex-start !important;
  padding: 3px !important;
  background: #f6f8fc !important;
  border-color: #cbd4e1 !important;
  margin: 0px !important;
  border: 1px solid #cbd4e1 !important;
  font-weight: 600 !important;
}

.edit-icon {
  color: #27364b !important;
}

.logo-select {
  width: 125px;
  display: block;
  margin: auto;
}

/* .MuiInput-underline:before{
  border-bottom: 0 !important;
} */
.select-box {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px;
}

.graph-bottom {
  color: #64748b;
  font-weight: 600;
}

.graph-button {
  width: 125px;
  text-transform: capitalize !important;
  font-size: 16px !important;
  border-color: #196773 !important;
  color: #196773 !important;
  font-weight: 600 !important;
  height: fit-content !important;
  border-radius: 8px !important;
}

.add-button {
  padding: 12px 16px !important;

  border: 1px solid #196773 !important;
  color: #196773 !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  background-color: #ffffff !important;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.add-field {
  padding: 8px 30px !important;
  margin: 5px 0px !important;
  border: 1px solid #196773 !important;
  color: #196773 !important;
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  background-color: #ffffff !important;
  width: -webkit-fill-available !important;
}

.refresh-icon {
  border: 1px solid #cbd4e1 !important;
  border-radius: 8px;
  width: 50px !important;
  height: 40px !important;
  padding: 8px;
  margin-left: 20px;
  cursor: pointer;
}

.refresh-black-area {
  border-radius: 8px;
  width: 50px !important;
  height: 40px !important;
  padding: 8px;
  margin-left: 20px;
  cursor: pointer;
}

.file-icon {
  background-color: #fff !important;
  border-radius: 10px !important;
  padding: 90px 35px !important;
  border: 1px solid #196773 !important;
}

.file-preview {
  width: 160px !important;
  height: 130px !important;
  margin: 0px 0px 0px 30px;
}

.file-preview-id-proof {
  width: 160px !important;
  height: 130px !important;
}

.image-container {
  margin-right: 10px; /* Add spacing between images */
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  position: relative;
}

.delete-wrapper {
  display: flex;
  justify-content: center; /* Center the delete icon horizontally */
  margin-top: 5px; /* Add spacing between image and delete icon */
  padding-left: 29px;
}

.tab-box > div > div > div {
  overflow: auto !important;
}

.image-preview {
  width: 400px !important;
  height: 240px !important;
  margin: 0px 0px 0 70px !important;
}

.image-box {
  width: 100%;
  background-color: #fff;
  padding: 30px 0px;
  margin-top: 5px;
  border-radius: 10px;
  overflow-x: auto;
}

.delete-image {
  height: 40px;
  cursor: pointer;
  margin-left: 12px;
}
.chapter-upload-image:hover {
  cursor: pointer;
  color: #fff;
  background-color: #e74a3b;
  border-color: #e74a3b;
  border-radius: 4px !important;
}

.image-dimension {
  max-width: 400px;
  max-height: 360px;
  margin: 0px 0px 0px 42px;
  border-radius: 8px;
}

.input-size {
  font-size: 10px !important;
}

.edit-card {
  border: 1px solid #cbd4e1;
  border-radius: 5px;
  padding: 5px;
}

.refresh {
  background: #fff;
  border: 1px solid #196773;
  width: 50px !important;
  height: 40px !important;
  padding: 5px;
  border-radius: 5px;
  color: #27364b;
}
.refresh:hover {
  background-color: #ffe6d6 !important;
  border: 1px solid #f26539 !important;
  color: #f26539 !important;
}
.reset-icon {
  background-color: #f6f8fc;
  border: 1px solid #cbd4e1;
  border-radius: 5px;
  color: #27364b;
  width: 60px;
  height: 60px;
  display: flex;
}

.select-opt {
  border: none;
  appearance: none;
  font-size: 16px;
  color: #27364b;
  font-weight: 600;
}

.detail-key {
  color: #27364b !important;
  text-align: end !important;
  font-size: 15px !important;
  vertical-align: baseline !important;
  white-space: nowrap;
}

.detail-value {
  color: #27364b !important;
  text-align: start !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.detail-head {
  color: #27364b !important;
  /* white-space: nowrap !important; */
  text-align: end !important;
}

.list-data > li > div > p {
  font-size: 10px !important;
}

.question-chip {
  background-color: #f6f8fc !important;
  border-radius: 5px !important;
  width: 200px !important;
  height: 36px;
}

.header-chip {
  border-color: #27364b !important;
  color: #27364b !important;
  font-weight: 600 !important;
  height: 25px !important;
  width: fit-content !important;
}

.apexcharts-legend {
  display: flex !important;
  align-items: baseline !important;
  right: 12px !important;
}

.pointer {
  width: 200px;
  height: 40px;
  position: relative;
  background: #ffffff;
  padding-top: 6px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.pointer:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #ffffff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.pointer:before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #ffffff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.point {
  width: 200px;
  height: 40px;
  position: relative;
  background: #ffffff;
  padding-top: 6px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

/* .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  cursor: pointer !important ;
  display: flex !important ;
  justify-content: flex-start !important ;
  align-items: center !important ;
  min-height: auto !important ;
padding: 6px 16px !important;
  box-sizing: border-box !important ;
  white-space: nowrap !important ;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 9px !important;
  border-radius: 50% !important;
}
.css-1km1ehz {
  align-items: center !important;
  position: relative !important;
  text-decoration: none !important;
  padding: 6px 16px !important;
  display: flex !important;
  justify-content: flex-start !important;
  min-height: auto !important ;
} */
.select-item {
  cursor: pointer !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  min-height: auto !important;
  padding: 6px 16px !important;
  box-sizing: border-box !important;
  white-space: nowrap !important;
}

.radio-btn > label > span:nth-child(1) {
  padding: 9px !important;
  border-radius: 50% !important;
}

.point:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #f6f8fc;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.point:before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #ffffff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.checks {
  display: inline !important;
}

.modal-btn {
  width: fit-content;
  line-height: normal;
  margin: auto;
  border: 1px solid #f26539;
  color: #f26539;
  padding: 6px;
  border-radius: 8px;
  display: block;
  margin-top: 10px;
}

.export-button {
  border: 1px solid #196773 !important;
  color: #196773 !important;
  text-transform: capitalize !important;
  border-radius: 8px !important;
}

.store-file {
  background-color: #fff !important;
  border-radius: 10px !important;
  padding: 64px 35px !important;
  border: 1px solid #196773 !important;
}

.top-heading {
  text-align: center;
  font-weight: 700;
  color: #27364b;
  margin: 5px 0px !important;
}

.table-scroll {
  height: 230px;
  overflow-y: scroll;
}

.table-scroll::-webkit-scrollbar {
  display: none;
}

.common-table-scroll {
  max-height: 380px;
  overflow-y: scroll;
}

.common-table-scroll::-webkit-scrollbar {
  display: none;
}

.user-filter {
  border: 1px solid #cbd4e1;
  border-radius: 8px;
  background: #ffffff;
  height: 42px;
}

.filter-text {
  font-weight: 600;
  display: inline-block;
  margin-right: 10px;
}

.user-header {
  height: fit-content !important;
  font-weight: 600 !important;
  background: #ffffff !important;
  text-transform: inherit !important;
  border-radius: 8px !important;
  margin-left: 3% !important;
}

.user-edit {
  border: 1px solid #f39a4c !important;
  color: #f39a4c !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  background: #fff !important;
  height: 45px;
  border-radius: 8px !important;
}

.cross-icon > button {
  position: absolute !important;
  color: #9e9e9e !important;
  padding: 8px !important;
  border-radius: 50% !important;
}

.issue-modal-savebtn {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  background-color: #2e7d32 !important;
  color: #fff !important;
  min-width: 64px !important;
  padding: 6px 16px !important;
  border-radius: 8px !important;
}

.issue-modal-cancelbtn {
  color: #fff !important;
  background-color: #1976d2 !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  min-width: 64px !important;
  padding: 6px 16px !important;
  border-radius: 8px !important;
}

.user-cancel {
  border: 1px solid #bb251a !important;
  color: #bb251a !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  background: #fff !important;
  height: 45px;
  border-radius: 8px !important;
}

.user-success {
  border: 1px solid #039855 !important;
  color: #039855 !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  background: #fff !important;
  height: 45px;
}

.border-none {
  border: none;
  font-weight: 600;
}

.dropdown-chip {
  border: 1px solid #cbd4e1;
  border-radius: 15px;
  background: #f6f8fc;
  line-height: 22px;
}

.assigned-avatar {
  background: #f6f8fc !important;
  width: 70px !important;
  height: 70px !important;
  border: 1px solid #cbd4e1 !important;
  color: #27364b !important;
  padding: 18px;
}

.assigned-label {
  float: right !important;
  border: 1px solid #27364b !important;
  height: fit-content !important;
  background: #f6f8fc !important;
  padding: 2px !important;
}

.ListItemText-subitem {
  display: flex;
  align-items: flex-start;
  padding: 0px !important;
}

.ListItemText-subitem > span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 16px;
}

.ListItemText-subitem:hover {
  color: #f26539 !important;
}

.list-item {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  /* border-bottom: none !important; */
  border-collapse: collapse !important;
}

.singleOrder :first-child .list-item {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.singleOrder :last-child .list-item {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.list-items {
  padding: 8px 8px 0px 8px;
  background: #f6f8fc;
}

.question-caption {
  display: inline-flex;
  border: 1px solid #cbd4e1;
  background: #f6f8fc;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 13px !important;
}

.align-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.tab-box {
  border: 1px solid #cbd4e1;
  border-radius: 8px;
  margin-bottom: 15px;
}

.tab-item {
  text-transform: capitalize !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.6) !important;
  padding: 12px 16px !important;
}

.marginbottom {
  margin-bottom: 10px;
}

.negative-margin {
  margin-left: -5px !important;
}

.button-tab {
  border: 1px solid #cbd4e1 !important;
  color: #64748b !important;
  border-radius: 8px !important;
  height: 40px !important;
  min-height: 40px !important;
  padding: 12px 16px !important;
  text-transform: capitalize !important;
  width: 100% !important;
  max-width: 175px !important;
}

.date-input > div {
  border-radius: 8px !important;
}

.button-tab-parent > div > span {
  background-color: ghostwhite !important;
  transition: none !important;
}

.courses-data {
  display: flex !important;
  color: #27364b !important;
}

.Lms-Overview-heading {
  font-size: 18px !important;
  color: #64748b !important;
  text-align: center !important;
  padding-top: 8px !important;
}

.lms-chart > div > svg > foreignObject > div {
  display: flex !important;
  justify-content: center !important;
  align-items: end !important;
  gap: 5px;
}

.lms-chart {
  display: flex !important;
  justify-content: center !important;
}

.zero-space {
  overflow-x: auto !important;
}

.line-chart-box {
  display: flex;
  justify-content: center;
}

.line-chart-btns1 {
  display: flex;
}

.detailed-btn {
  border: 1px solid #196773 !important;
  border-radius: 8px !important;
  text-transform: inherit !important;
  color: #196773 !important;
  margin-bottom: 32px !important;
}

.lms-edit-btn {
  text-transform: inherit !important;
  border-radius: 8px !important;
  background: #f39a4c !important;
  gap: 5px !important;
}

.button-tab.Mui-selected {
  background-color: #fff !important;
  color: #f26539 !important;
  border-color: #f26539 !important;
}

.input-divider {
  display: grid;
  align-items: center;
}

.error {
  color: red;
  border-color: 1px solid red !;
}

.text-field-with-error {
  border: 1px solid;
  border-color: #c4c4c4;
  outline: 1px solid red !important;
  outline-offset: 1px;
}

.company-head {
  text-align: start;
  margin: 10px 0px;
  font-weight: bold;
  font-size: 20px !important;
}
.circle {
  list-style-type: circle;
}

.text-short {
  width: 120px !important;
  display: block !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden;
  color: #27364b;
  text-align: center;
}
.text-short-checklist {
  width: 20px !important;
  display: block !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden;
  color: #27364b;
  text-align: left;
}

.text-short-assesment {
  width: 150px !important;
  display: block !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden;
  color: #fff !important;
  text-align: left;
}

.text-short-allform {
  /* width: 340px !important; */
  display: block !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden;
  color: #27364b;
  text-align: center;
}

.text-short2 {
  width: 150px !important;
  display: inline-block !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden;
  color: #27364b;
}

.css-131hyt {
  overflow: hidden !important;
}

.spinner-border {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 90%;
}

/* table {
  width: 100%;
}
.first {
  width: 20%;
}

.ellipsis {
  position: relative;
}
.ellipsis:before {
  content: '&nbsp;';
  visibility: hidden;
}
.ellipsis span {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
} */
.elements-style {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.list-section {
  width: 100%;
  border-radius: 8px !important;
  font-size: 16px !important;
  border: 2px solid #cbd4e1 !important;
  cursor: pointer !important;
}

@keyframes blinkChecklist {
  50% {
    border-color: #f39a4c;
  }
}

.blinking {
  /*or other element you want*/
  animation: blinkChecklist 0.5s linear infinite;
}

.duedata {
  border-left: 5px solid;
  height: 100%;
  padding: 13px 0px;
  font-weight: 600;
  width: -webkit-fill-available;
  display: inline;
  /* align-items: center;
  justify-content: center; */
}

.capitalize {
  text-transform: capitalize;
}

.daily-hover:hover {
  background-color: lightgreen !important;
  border: 1px solid green !important;
}

.monthly-hover:hover {
  background-color: lightskyblue !important;
  border: 1px solid blue !important;
}

.hourly-hover:hover {
  background-color: rgb(190, 26, 26) !important;
  border: 1px solid red !important;
}

.weekly-hover:hover {
  background-color: lightsalmon !important;
  border: 1px solid orange !important;
}

.nofreq-hover:hover {
  background-color: rgb(255, 120, 120) !important;
  border: 1px solid brown !important;
}

.list-items {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.list-itemsss {
  align-items: center;
}

.attach-button {
  background-color: #196773 !important;
  color: #fff !important;
  height: 40px;
  text-transform: capitalize !important;
  border-radius: 8px !important;
}

.submission-view:hover {
  color: #196773 !important;
}

.submission-view {
  margin: auto !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.monthDate {
  display: flex !important;
  flex-direction: row !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row {
  margin-top: 5px !important;
  display: flex !important;
  justify-content: center !important;
}

.user-count {
  border: 1px solid #cbd4e1;
  border-radius: 8px;
  background: #ffffff;
  padding: 8px 18px 5px 18px;
}

.css-iclwpf .MuiDataGrid-row {
  margin-top: 15px !important;
}

.score-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 5px;
}

.question-type-div {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

/* Custom properties */
:root {
  --tooltip-text-color: white;
  --tooltip-background-color: #f26539;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 12px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -1);
}

/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1);
}

/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}

.active-bg {
  background-color: #64748b !important;
  color: #ffffff !important;
  border: 1px solid white !important;
  border-radius: 8px !important;
  height: 42px !important;
  min-height: 35px !important;
  padding: 7px 12px !important;
  text-transform: inherit !important;
}
.active-tabs-content {
  background-color: #d3d3d3 !important;
}

.tabs-content {
  background-color: #ffffff !important;
}

.active-bg > P {
  background-color: #fff !important;
  color: #27364b !important;
  border-radius: 100px !important;
  padding: 3px 12px 3px 12px !important;
  color: #f26539 !important;
}
.pending-success-btn {
  border-radius: 8px !important;
  text-transform: capitalize !important;
}
.tabs-content {
  /* font-size: x-small !important; */
  border: 1px solid #64748b !important;
  /* max-width: 10px !important; */
  color: #64748b !important;
  border-radius: 10px !important;
  height: 42px !important;
  min-height: 35px !important;
  padding: 7px 12px !important;
  text-transform: inherit !important;
  /* text-align: center; */
}

.css-1jv5v16 {
  background-color: none !important;
}

/* blink */
.blink {
  animation: blinker 0.75s linear infinite;
  color: red;
  font-family: sans-serif;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.MuiDataGrid-cell {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.submission-avatar {
  width: 100px;
  height: 100px;
  background-color: #f6f8fc;
  border: 1px solid #cbd4e1;
  margin: auto;
  margin-top: 25px;
  border-radius: 50px;
  padding: 5px;
}

.apexcharts-tooltip {
  margin-left: 0px !important;
  /* margin-left: 26px !important; */
}

.item-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* #scrollable {
  height: fit-content;
} */

@keyframes blinkQuest {
  50% {
    opacity: 0;
  }
}

.Iconblink {
  /*or other element you want*/
  animation: blinkQuest 1s linear infinite;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

.label-upload {
  align-items: center;
  display: flex;
}

.carousel-style {
  height: 100% !important;
  /* margin-top: 10px !important; */
  display: flex;
  /* flex-direction: column-reverse; */
  width: 100%;
}

.slider-wrapper .carousel-img-wrapper {
  position: relative;
}

.slider-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.carousel-img-details-wrapper {
  position: absolute;
  height: 400px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 30px;
}

.carousel-img-details-wrapper .image-log-wrapper {
  display: none;
}

.image-log-wrapper .like-wrapper {
  column-gap: 20px;
  display: flex;
  align-items: center;
}

.carousel-img-details-wrapper:hover {
  background: rgba(0, 0, 0, 0.1);
}

.carousel-img-details-wrapper:hover .image-log-wrapper {
  display: flex;
}

.custom-thumb-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 15%;
  row-gap: 5px;
}

.custom-carousel-wrapper {
  width: 85%;
  display: flex;
  flex-direction: column;
}
.custom-thumb-wrapper .thumb-prev,
.custom-thumb-wrapper .thumb-next {
  width: 60px;
  background: #f6f8fc;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  cursor: pointer;
}

.custom-thumb-wrapper .selected-img {
  border: 2px solid black;
}

.custom-thumb-wrapper .custom-thumb-img {
  /* padding: 4px; */
  display: flex;
  cursor: pointer;
}
.custom-thumb-wrapper .custom-thumb-img img {
  margin: 4px;
}

.custom-thumb-wrapper .custom-thumb-img:hover {
  border: 2px solid black;
}

.imagview-image-log {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.qusImg-container img {
  transition: transform 0.2s;
  width: 100%;
  height: 200px;
  border-radius: 8px;
  object-fit: cover;
}
.qusImg-container:hover img {
  transform: scale(1.02);
  -webkit-filter: brightness(70%);
}

.qusImg-container:hover .like-dislike-container {
  opacity: 1;
}

.user-image {
  width: 80px;
  height: 80.5px;
}

.user-image > img {
  width: 78px;
  height: 78px;
  border-radius: 5px;
}

.legend {
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  opacity: 0.25;
  transition: opacity 0.35s ease-in-out;
}

.align-left {
  display: block;
  text-align: start;
}

.label-upload {
  align-items: center;
  display: flex;
}

.user-image:hover {
  cursor: pointer;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}
/* .bottom-left {
  position: absolute;
  bottom: 65px !important;
  left: 16px !important;
  background-color: green !important;
  width: 90% !important;
  border: 1px solid red !important;
} */

.carousel .thumb img {
  /* width: 100% !important; */
  height: 100px !important;
}
.qusImg-container {
  position: relative;
  text-align: center;
  color: white;
  max-width: 800px;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
/* .qusImg-container .content {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
}
.qusImg-container .content > p {
  width: fit-content;
} */
.download-all-img {
  page-break-after: always;
}

.dowImg-container {
  text-align: center;
  color: white;
  max-width: 800px;
  margin-bottom: 10px;
}

.dowImg-container .like-dislike-container p {
  background-color: rgb(0, 0, 0, 0.5);
  border-radius: 3px;
  width: fit-content;
  padding: 3px 7px;
  margin: 0 !important;
  gap: 7px;
}

.dowImg-container img {
  transition: transform 0.2s;
  width: 100%;
  height: 400px;
  border-radius: 8px;
  object-fit: contain;
}

.dowImg-container .captured-img {
  border: 2px solid #000;
}

.dowImg-container .rating-contaier {
  display: flex;
  z-index: 10;
  background-color: rgb(0, 0, 0, 0.5);
  border-radius: 3px;
}

.dowImg-container .like-dislike-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 10px;
  float: right;
  height: inherit;
  z-index: 10;
}

.qusImg-container:hover .like-dislike-container {
  opacity: 1;
}

.qusImg-container .like-dislike-container p {
  background-color: rgb(0, 0, 0, 0.5);
  border-radius: 3px;
  width: fit-content;
  padding: 3px 7px;
  margin: 0 !important;
  gap: 7px;
}

.qusImg-container:hover .rating-contaier {
  opacity: 1;
}

.Img-container {
  position: relative;
  /* max-width: 60%; */
  align-self: center;
}
.Img-container .content {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
}
.Img-containerr .content > p {
  width: fit-content;
}
.map-box {
  margin: 5px 0px 10px 0px;
  padding-right: 7px !important;
  padding-left: 7px !important;
  /* padding-bottom: 5px !important; */
}
.map-box > div {
  width: 100% !important;
}
.like-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 14%; */
  float: right;
  margin-right: 20px;
  height: inherit;
}
.rating-contaier {
  display: flex;
}
.text-para {
  margin-left: 10px;
}

.qusImg-container .rating-contaier {
  display: flex;
  /* opacity: 0; */
  position: absolute;
  /* bottom: 50px; */
  left: 10px;
  z-index: 10;
  background-color: rgb(0, 0, 0, 0.5);
  border-radius: 3px;
}

.qusImg-container .like-dislike-container {
  position: absolute;
  /* bottom: 50px; */
  right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 10px;
  /* width: 17%; */
  float: right;
  /* margin-right: 11%; */
  height: inherit;
  /* opacity: 0; */
  z-index: 10;
}
.comment-contaier {
  display: flex;
}
.likeIcon {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
}
.likeIcon:hover {
  cursor: pointer;
}

.top-right {
  position: absolute;
  top: 8px;
  right: 36px;
}
.like-dislike-container > p:hover {
  cursor: pointer;
}
.lms-report-card {
  display: flex;
  flex-direction: row;
  overflow-x: auto !important;
}
.lms-report-select {
  background: url("../assets/icons/svg-icons/Down.svg");
  appearance: none;
  background-repeat: no-repeat;
  background-position: right;
  width: 100px;
}
.check-select2 {
  border: 1px solid #cbd4e1;
  height: 32px;
  /* width: fit-content; */
  border-radius: 8px;
  padding: 4px 16px;
  color: #27364b;
  font-weight: 600;
  display: block;
  margin: 10px;
}
.Delete-Error-btn {
  border: 1px solid #bb251a !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
}
.urlColor {
  color: blue;
  background-color: transparent;
  text-decoration: none;
}
.urlColor:hover {
  color: rgb(255, 102, 0);
  background-color: transparent;
  text-decoration: underline;
}
.create-assessment-header {
  gap: 10px;
  display: flex;
}
.audit-header {
  align-items: center !important;
}
.user-performance-name {
  display: flex !important;
  white-space: nowrap;
}
.userlist-header-btn {
  display: flex !important;
  gap: 5px;
  height: 42px;
}
.datalist-header-stack {
  gap: 5px;
  display: flex;
}

.spr-viewall {
  color: #64748b !important;
  text-decoration: none !important;
  /* line-height: 35px !important; */
  /* display: flex !important; */
  /* margin: 15px 55px !important; */
  /* font-size: 14px; */
  font-weight: 600;
}
.submenu-item-storeinfo {
  text-decoration: none;
  color: #27364b !important;
}
.done-btn-storeinfo {
  background-color: #196773 !important;
  color: #ffffff !important;
  padding: 5px 12px !important;
  border: 1px solid transparent !important;
  text-transform: capitalize !important;
  height: 40px !important;
  border-radius: 8px !important;
}
.colomn-padding-storeinfo {
  padding-top: 10px !important;
}
.company-info-head {
  padding: 5px 12px !important;
  border: 1px solid transparent !important;
  text-transform: capitalize !important;
  height: 60px !important;
  border-radius: 8px !important;
  padding-top: 15px !important;
}
.storedetail-headings {
  padding: 8px !important;
  margin-top: 16px !important;
  font-weight: bold !important;
  text-align: left !important;
}
.storedetail-map {
  display: flex;
  justify-content: center;
  margin: 5px 0px 20px 0px;
}
.storedetail-map > div {
  width: 100% !important;
  height: 30vh !important;
}
.company-info-header {
  margin-bottom: 20%;
}

.individual-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 31%) 0px 0px 1px 0px, rgb(0 0 0 / 25%) 0px 24px 36px;
  padding: 32px;
  border-radius: 8px;
  max-height: 500px !important;
  overflow-y: auto !important;
}
.image-data-stack {
  gap: 10px;
}
.imagelog-grid-padding {
  padding: 24px;
}
.Checklist-Detail-direction {
  gap: 10px;
}
.user-img-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.grid-table-scroll > :nth-child(2) > :nth-child(2) > div {
  overflow-y: auto !important;
}
.checklist-report-header {
  gap: 5px;
  align-items: center;
}
.checklist-report-grid-table {
  width: 100% !important;
  min-width: 1100px !important;
}
.checklist-report-grid-table > :nth-child(1) > :nth-child(1) > th {
  white-space: nowrap;
}
.allform-head-time {
  display: flex !important;
  gap: 10px;
}
.ql-container {
  height: 10rem !important;
}
.button-rigth {
  display: flex;
  flex-direction: column;
}
.button-reset {
  display: flex;
  justify-content: end;
}
.box {
  margin-top: -3rem;
}
.content-list > ol > li {
  list-style: unset;
}
.content-list > ul > li {
  list-style: unset;
}
.check-report-grid-table {
  width: 100% !important;
  overflow: auto !important;
  position: sticky !important;
  top: 0 !important;
  width: 100% !important;
  min-width: 960px !important;
  padding-bottom: 0px;
}
/* Media queries to adjust styles based on screen size */
@media (min-width: 768px) {
  .table-left-border-success {
    border-left: 5px solid #4caf50;
  }
  .table-left-border-danger {
    border-left: 5px solid #f44336;
  }
  .duedata {
    border-left: 5px solid #ffc107;
  }
  .text-short {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .table-left-border-success {
    border-left: 2px solid #4caf50;
  }
  .table-left-border-danger {
    border-left: 2px solid #f44336;
  }
  .duedata {
    border-left: 2px solid #ffc107;
  }
  .text-short {
    font-size: 12px;
  }
}

.carousel-status {
  position: absolute;
  top: 5% !important;
  left: -88% !important;
  padding: 5px;
  font-size: 14px !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #363535 !important;
}
.comment-button {
  margin-top: 30px;
  padding: 6px 12px 6px 12px;
  background: rgb(12 36 49);
  color: #ffffff !important;
}

.comment-button:hover {
  background-color: rgba(12, 36, 49, 0.8) !important;
}
.cancel-button:hover {
  background-color: rgba(236, 57, 1, 0.8) !important;
}

.center-placeholder ::placeholder {
  text-align: center;
}

.custom-placeholder::placeholder {
  color: grey !important;
}

.transparentLoader {
  margin: 0px;
  padding: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0);
  z-index: 30001;
  opacity: 0.8;
}
.centerLoaderText {
  position: absolute;
  color: White;
  top: 45%;
  left: 45%;
}

.transparentLoaderWrapper {
  /* background-color: rgb(0 0 0); */
  z-index: 30001;
  opacity: 0.8;
  height: inherit;
}

.centerLoaderTextWrapper {
  position: absolute;
  color: White;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.centerLoaderInnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.training-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.quill-training .ql-container {
  height: 19rem !important;
}
.assessment {
  background-color: #f9f9f9 !important;
}

.custom-tooltip {
  background-color: white !important;
  color: black;
  border: none !important;
  box-shadow: none !important;
}
@media print {
  @page {
    margin-top: 100px !important; /* Adjust as needed */
    margin-left: 20px !important; /* Adjust as needed */
    margin-right: 20px !important; /* Adjust as needed */
    height: 100% !important;
  }
  #invoicePrint {
    margin-top: 0px !important; /* Adjust as needed */
    width: 100% !important;
  }
}
@media (min-width: 425px) and (max-width: 768px) {
  .chat-container {
    overflow-x: auto !important;
    scroll-behavior: smooth;   
  }
}

.chat-container {
  display: flex;
  height: 83vh;
  overflow: hidden;
}

.main-chat {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #fff;
}

.chat-box {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 16px;
  background-color: #fff;
  position: relative;
}

.chat-box .watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(0, 0, 0, 0.05);
  font-size: 5rem;
  white-space: nowrap;
  pointer-events: none;
  z-index: 0;
}

.input-box {
  display: flex;
  padding: 16px;
  border-top: 1px solid #ddd;
  position: relative;
  background-color: #fff;
}

.message-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

.message-content {
  max-width: 70%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.sent {
  align-self: flex-end;
  text-align: right; /* Align the message bubble to the right */
}

.received {
  align-self: flex-start;
  text-align: left;
}

.sent-header,
.received-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.sent-header {
  justify-content: flex-end;
}

.received-header {
  justify-content: flex-start;
}

.message-text {
  border-radius: 10px;
  padding: 8px;
  display: flex;
  justify-content: space-between; /* Ensure text and icon are on the same line */
  align-items: center; /* Align text and icon vertically */
}

.sent .message-text {
  background-color: #add8e6; /* Light blue background for sent messages */
  text-align: left; /* Align text within the bubble to the left */
}

.received .message-text {
  background-color: #f1f1f1; /* Light gray background for received messages */
  text-align: left; /* Ensure text is aligned to the left */
}

.message-text span {
  flex-grow: 1; /* Allow text to take available space */
  white-space: pre-wrap; /* Ensure text wraps properly */
  word-break: break-word; /* Break words if necessary */
}

.sent-text {
  align-self: flex-start; /* Align text to the left within the message box */
}

.message-time {
  font-size: 0.75rem;
  color: #777;
  margin-left: 8px;
}

.reaction-icons {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
}

.message-item:hover .reaction-icons {
  display: flex;
}

.reactions {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.reaction-count {
  margin-right: 8px;
}

.no-chat-selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #132528;
  background-color: #fff;
  position: relative;
}

.no-chat-message {
  font-weight: bold;
  z-index: 1;
}

.watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(0, 0, 0, 0.05);
  font-size: 10rem;
  white-space: nowrap;
  z-index: 0;
  pointer-events: none;
}

.header-card-chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  width: 100%;
  background-color: #fff;
  height: 77px;
  min-height: 77px;
  padding: 0 16px;
}

.header-left-chat,
.header-right-chat {
  display: flex;
  align-items: center;
}

.header-right-chat .MuiTextField-root {
  margin-right: 8px;
  width: 200px;
  align-self: center;
}

.header-right-chat .MuiIconButton-root {
  margin-right: 8px;
}

.more-options {
  margin-left: 8px; /* Add space between text and icon */
}

.sidebar {
  width: 300px;
  border-right: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
  /* overflow-y: auto; */
}

.sidebar-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.sidebar-search {
  margin: 10px 0;
}

.sidebar-search .MuiOutlinedInput-root {
  width: 225px; /* Adjust the width as needed */
}

.sidebar-search .MuiInputBase-root {
  height: 42px; /* Set desired height */
}

.sidebar-search .MuiInputBase-input {
  padding: 6px 10px; /* Adjust padding */
}

.sidebar-navigation {
  margin-top: 10px;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  padding-bottom: 20px;
}

.sidebar-navigation .MuiList-root {
  padding: 0;
}

.sidebar-navigation .MuiTypography-root .MuiIconButton-root {
  padding: 6px;
}

.sidebar-navigation .MuiList-root:last-child {
  /* for bottom margin in scroll side bar  */
  padding-bottom: 120px;
}

.sidebar-navigation .MuiTypography-root .MuiIconButton-root svg {
  font-size: 20px;
}


/* POHeader lms  started*/
.custom-select {
  width: 200px;
  height: 42px;
  border-radius: 8px;
}

.centered-menu-item {
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.custom-menu-paper {
  max-height: 200px;  
  width: 200px;       
  display: flex;     
  flex-direction: column; 
}

.addButton{
  height: 45px !important;
}
/* POHeader lms ended */

.header-card-container{
  padding: 0px 10px;
  width: 100%;
  margin: 0;
  align-items: center;
}

.padding-0{
  padding: 0px;
}

/* SchecularToggel.css  started*/
.toggle-switch {
  width: 60px; 
  height: 34px; 
  background-color: #ccc; 
  border-radius: 34px; 
  position: relative; 
  cursor: pointer; 
  transition: background-color 0.2s; 
}

.toggle-switch.on {
  background-color: #4caf50; 
}

.toggle-thumb {
  width: 26px; 
  height: 26px; 
  background-color: white; 
  border-radius: 50%; 
  position: absolute; 
  top: 4px; 
  left: 4px; 
  transition: transform 0.2s; 
}

.toggle-switch.on .toggle-thumb {
  transform: translateX(26px); 
}
/* SchecularToggel.css  ended*/

.date-width > div {
  width: auto;
}

.header-filter{
  display: block;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn-elements {
    margin-top: 43px;
  }
}

@media (max-width: 767px) {
  .responsive-layout-mode {
    display: grid !important;
    gap: 8px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .responsive-layout-mode {
    display: flex;
    gap: 8px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .tabs-container-flex{
    position: absolute;
    right: 5px;
  }
}

@media  (min-width: 425px) and (max-width: 768px) { 
  .user-header-margin{
    margin-left: 0% !important;
    height: 45px !important;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .questions-tab-flex{
    flex-wrap: nowrap !important;
    display: flex !important;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .toolbar-container{
    flex-wrap: nowrap !important;
  }

}

@media screen and (min-width: 426px) and (max-width: 800px){
  .toolbar-active > div > div {
    display: flex !important;
    gap: 8px !important;
  }
}
@media (max-width: 425px){
  .toolbar-small > div{
    display: contents !important;
    
  }
}